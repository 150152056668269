import BaseAdapter from '@adc/app-infrastructure/adapters/base-adapter';
import { BuildURLMixin } from '@ember-data/adapter';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { getCookie } from '@adc/ember-utils/utils/browser-helpers';
import {
    InvalidAntiForgeryToken,
    ServerProcessingError,
    ProcessingError
} from '@adc/ajax/enums/AjaxResponseHttpCode';

/**
 * @classdesc
 * Sets up properties for communicating with the backend.
 */
export default class ApplicationAdapter extends BaseAdapter.extend(
    BuildURLMixin
) {
    @service notificationManager;
    @service session;

    /**
     * Namespace for API requests.
     */
    namespace = 'api';

    /**
     * Handles cases where AFG is invalid or login has expired. Application will reload
     * to get new AFG if AFG has expired or invalidate session if authentication JWT is expired.
     *
     * @param status {Number}
     * @param error {String}
     */
    handleError(status, error) {
        // Handle the case where an anti-forgery token is not valid
        // or is missing or if there is a server error. For either case,
        // we invalidate the current user session.
        if (
            status === ServerProcessingError ||
            status === InvalidAntiForgeryToken
        ) {
            this.notificationManager.addError(error);
            this.session.invalidate();
        }
    }

    /**
     * Expected request headers appended to each request.
     *
     * @override
     *
     * @function
     * @returns {{Accept: {String}, Content-Type: {String}, AjaxRequestUniqueKey: {String}, Authorization: {String}}}
     */
    get headers() {
        const headers = {
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/json',
            AjaxRequestUniqueKey: getCookie('afg')
        };

        // Set Ember Simple Auth login token on header.
        const { token } = this.session.data.authenticated;
        if (!isEmpty(token)) {
            headers.Authorization = token;
        }

        return headers;
    }

    // region normalizing responses
    /**
     * @param  {number} status
     * @param {Object} headers
     * @param {Object} payload
     *
     * @returns {Array<String>} errors payload
     */
    normalizeErrorResponse(status, headers, payload) {
        payload = payload || { errors: null };
        const errors = payload.errors || [{ detail: null }];

        // Handle errors.
        errors.forEach((error) =>
            this.handleError(parseInt(error.status), error.detail)
        );

        const errorMessages = errors
            .map((error) => error.detail)
            .filter((error) => error);

        // Is this a processing error then add to notification manager.
        if (status === ProcessingError) {
            errors
                .filter(({ code }) => code === ProcessingError)
                .forEach(({ detail }) =>
                    this.notificationManager.addError(detail)
                );

            return errors;
        }

        return errorMessages.length ? errorMessages : null;
    }

    /** @override **/
    findRecord(store, type, id, snapshot) {
        // If query parameters are passed in to findRecord, then send it along with GET request.
        if (snapshot.adapterOptions?.queryParams) {
            const url = this.buildURL(
                type.modelName,
                id,
                snapshot,
                'findRecord'
            );

            return this.ajax(url, 'GET', {
                data: snapshot.adapterOptions.queryParams
            });
        }

        // Otherwise, just call base implementation.
        return super.findRecord(...arguments);
    }
}
