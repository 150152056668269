import ENV from 'unattended-showing/config/environment';

/**
 * Environment settings instance initializer
 *
 * @param {Ember.ApplicationInstance} applicationInstance
 */
export function initialize(applicationInstance) {
    let envSettings = applicationInstance.lookup('service:envSettings');
    if (envSettings) {
        envSettings.setEnvironment(ENV);
    }
}

export default {
    initialize
};
