import BaseErrorReporting from '@adc/app-infrastructure/services/base-error-reporting';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

/**
 * @classdesc
 * The Sentry error reporting service.
 */
export default class ErrorReportingService extends BaseErrorReporting {
    @service envSettings;
    @service router;
    @service store;

    /** @override */
    get errorsToIgnore() {
        return [
            ...super.errorsToIgnore,
            ...['Unhandled Promise error detected', 'TransitionAborted'].map(
                (value) => ({
                    propertyType: 'message',
                    value
                })
            )
        ];
    }

    /**
     * Initializes error reporting.
     */
    initializeForSession() {
        const {
            errorReporting: { dsn, environment, showDebugInfo, isEnabled } = {}
        } = this.envSettings.getEnvironment();
        if (dsn) {
            this.configure({ dsn, environment, showDebugInfo });
        }

        set(this, 'isEnabled', isEnabled);
    }

    /** @override **/
    beforeSend(event) {
        event = super.beforeSend(...arguments);
        const { isEnabled } = this;
        return isEnabled ? event : null;
    }
}
