import Controller from '@ember/controller';
import { computed, action } from '@ember/object';
import DropdownSelectItem from '@adc/ui-components/utils/dropdown-select-item';
import { inject as service } from '@ember/service';
import {
    createRowItem,
    createNativeSubField,
    createDropdownSubField
} from '../../../components/input-form-row/component';
import { validateTextInputCustomRegex } from '../../../utils/validators';

/**
 * Get DropdownSelectItem options for credit card expiration year.
 *
 * @function
 * @private
 * @static
 * @returns [Array<DropdownSelectItem>]
 */
function creditCardExpirationMonthOptions() {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    return Array.from(new Array(12), (_, index) =>
        DropdownSelectItem.create({
            name:
                (index + 1).toLocaleString('en-US', {
                    minimumIntegerDigits: 2
                }) +
                ' - ' +
                monthNames[index],
            value: index + 1
        })
    );
}

/**
 * Get DropdownSelectItem options for credit card expiration month.
 *
 * @function
 * @private
 * @static
 * @returns {Array<DropdownSelectItem>}
 */
function creditCardExpirationYearOptions() {
    const currentDate = new Date();

    return Array.from(new Array(11)).map((_, index) => {
        const newDate = new Date(currentDate);
        newDate.setFullYear(currentDate.getFullYear() + index);
        const year = newDate.getFullYear();

        return DropdownSelectItem.create({
            name: year,
            value: year
        });
    });
}

/**
 * Get DropdownSelectItem options for country dropdown.
 *
 * @function
 * @private
 * @static
 * @returns {Array<DropdownSelectItem>}
 */
function createCountryListItems(countryData) {
    return Object.keys(countryData).map((enumValue) =>
        DropdownSelectItem.create({
            name: countryData[enumValue].name,
            value: enumValue
        })
    );
}

/**
 * Get DropdownSelectItem options for states dropdown.
 *
 * @function
 * @private
 * @static
 * @returns {Array<DropdownSelectItem>}
 */
function createStateListItems(states) {
    return (states || []).map((name) =>
        DropdownSelectItem.create({
            name,
            value: name
        })
    );
}

const fnTextValidate = validateTextInputCustomRegex.bind(null, /[a-zA-Z]+/);
const fnNumberValidate = validateTextInputCustomRegex.bind(null, /^[0-9]+$/);

/**
 * @classdesc Verification controller for user sign-up.
 *
 * @class context.sign-up.verification.SignUpVerificationController
 * @extends Controller
 *
 */
export default class SignUpVerificationController extends Controller {
    @service cookies;
    @service store;
    @service ajax;
    @service loginPendingBooking;
    @service router;

    /**
     * Returns object for country data
     *
     * @function
     * @returns {Array<{name: {String}, states: Array<String>, address: {zipLength: {Number}, zipValidator: {Function}}}>}
     */
    @computed('model.countries')
    get countryData() {
        // eslint-disable-next-line ember/no-array-prototype-extensions
        return this.model.countries
            .toArray()
            .map((country) => {
                return {
                    [country.id]: {
                        name: country.countryName,
                        states: country.states,
                        address: {
                            zipLength: country.zipLength,
                            zipValidator: validateTextInputCustomRegex.bind(
                                null,
                                new RegExp(country.zipValidator)
                            )
                        },
                        zipFieldName: country.zipFieldName,
                        stateFieldName: country.stateFieldName
                    }
                };
            })
            .reduce(function (obj, item) {
                for (let key in item) obj[key] = item[key];
                return obj;
            }, {});
    }

    /**
     * Returns object for generating credit card verification user input fields.
     *
     * @function
     * @returns {Array<{label: {String}, subFields: Array<{inputType: {String}, propertyName: {String}, placeHolder: {String}, valueChange: {Function}, dropdownItems: Array<DropdownSelectItem>, maxLength: {Number}}>, validator: {Function}, errorMessage: {String}}>}
     */
    @computed(
        'countryData',
        'model.creditCardVerification',
        'updateModelProperty'
    )
    get userInfoFields() {
        return [
            createRowItem(
                'First name',
                [createNativeSubField('firstName')],
                fnTextValidate,
                'Please enter your first name.'
            ),
            createRowItem(
                'Last name',
                [createNativeSubField('lastName')],
                fnTextValidate,
                'Please enter your last name.'
            ),
            createRowItem(
                'Card number',
                [createNativeSubField('cardNumber', 'text', 16)],
                fnNumberValidate,
                'Please enter a valid credit card number.'
            ),
            createRowItem('Expiration', [
                createDropdownSubField(
                    'expirationMonth',
                    this.updateModelProperty,
                    creditCardExpirationMonthOptions(),
                    'Month'
                ),
                createDropdownSubField(
                    'expirationYear',
                    this.updateModelProperty,
                    creditCardExpirationYearOptions(),
                    'Year'
                )
            ]),
            createRowItem(
                'Security code',
                [createNativeSubField('securityCode', 'password')],
                fnNumberValidate,
                'Please enter the security code on the credit card.'
            ),
            createRowItem('Country', [
                createDropdownSubField(
                    'country',
                    this.updateModelProperty,
                    createCountryListItems(this.countryData),
                    'Country'
                )
            ])
        ];
    }

    /**
     * Returns object for generating billing address user input fields.
     *
     * @function
     * @returns {Array<{label: {String}, subFields: Array<{inputType: {String}, propertyName: {String}, placeHolder: {String}, valueChange: {Function}, dropdownItems: Array<DropdownSelectItem>, maxLength: {Number}}>, validator: {Function}, errorMessage: {String}}>}
     */
    @computed(
        'updateModelProperty',
        'countryData',
        'model.creditCardVerification.country'
    )
    get addressInfoFields() {
        const countryData = this.countryData;
        const selectedCountry = this.model.creditCardVerification.country;
        return selectedCountry === 0
            ? []
            : [
                  createRowItem(
                      'Address',
                      [createNativeSubField('address')],
                      fnTextValidate,
                      'Please enter your address.'
                  ),
                  createRowItem(
                      'City',
                      [createNativeSubField('city')],
                      fnTextValidate,
                      'Please enter your city.'
                  ),
                  createRowItem(countryData[selectedCountry].stateFieldName, [
                      createDropdownSubField(
                          'state',
                          this.updateModelProperty,
                          createStateListItems(
                              countryData[selectedCountry].states
                          ),
                          countryData[selectedCountry].stateFieldName
                      )
                  ]),
                  createRowItem(
                      countryData[selectedCountry].zipFieldName,
                      [
                          createNativeSubField(
                              'zipCode',
                              'text',
                              countryData[selectedCountry].address.zipLength
                          )
                      ],
                      countryData[selectedCountry].address.zipValidator,
                      `Please enter your ${countryData[selectedCountry].zipFieldName}.`
                  )
              ];
    }

    /**
     * Sends user credit card information to backend server to be verified.
     *
     * @function
     * @param {models.CreditCardVerification} model
     * @returns {models.CreditCardVerification|promise}
     */
    @action async save(model) {
        // Send credit card information to backend and redirect to profile route if successful.
        try {
            let creditCardVerification = await model.creditCardVerification;
            await creditCardVerification.save();

            // Remove cached versions of profile so next time we read, we grab a new version which should contain
            // fresh cc validation state.
            this.store.unloadAll('profile');
            const redirectUrl = this.cookies.read(
                'ember_simple_auth-redirectTarget'
            );
            // eslint-disable-next-line ember/no-array-prototype-extensions
            this.cookies.clear('ember_simple_auth-redirectTarget');
            if (this.loginPendingBooking.hasPendingAppointments) {
                await this.loginPendingBooking.processPendingAppointments();
            } else {
                this.router.transitionTo(redirectUrl || 'context.profile');
            }
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to verify credit card information. Please try again later.'
            );
        }
    }

    /**
     * Helper action to set model properties. Used by the ADC dropdown on-change.
     *
     * @function
     * @param model
     * @param property
     * @param value
     */
    updateModelProperty(model, property, value) {
        model.set(property, value);
    }
}
