import { set } from '@ember/object';
import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import addMinutes from 'date-fns/addMinutes';
import addDays from 'date-fns/addDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import { utcToZonedTime } from 'date-fns-tz';
import { inject as service } from '@ember/service';
import { datesAreOnSameDay } from '../../utils/datetime-utils';

/**
 * Component for showing, scheduling, and deleting Self-Guided Tours time slots.
 */
export default class AppointmentSchedule extends Component {
    @service loginPendingBooking;
    @service checkIn;
    @service modals;
    @service mockTime;

    /**
     * Whether the appointment table should be shown or if there is already an existing appointment and we
     * want to hide the table.
     *
     * @type {boolean}
     */
    @not('args.model.hasAppointment')
    hasNoAppointment;

    /**
     * Stores selected appointment datetime.
     *
     * @type {Date}
     */
    selectedDate = null;

    /**
     * Number of days for which appointments can be booked in advance. There is currently no dealer setting for this.
     * Need to consult with PM to see if this is desired.
     *
     * @type {Number}
     */
    days = 7;

    /**
     * Get the collection of unavailable appointment blocks for this community.
     *
     * @type {Array<{models.SanitizedAppointment}>}
     */
    @alias('args.model.unavailablePeriods')
    unavailablePeriods;

    /**
     * Get end time of appointment.
     *
     * @param {Date} date
     * @param {Number} appointmentLength
     *
     * @returns {Date}
     */
    getAppointmentEndDate(date, appointmentLength) {
        return addMinutes(date, appointmentLength);
    }

    /**
     * Checks whether the time slot is available.
     *
     * @param {{startDateTime: {Date}, available: {boolean}}} timeSlotStart
     * @param {[Date]} unavailablePeriods
     * @param {Number} appointmentLength
     *
     * @returns {boolean}
     */
    isTimeSlotAvailable(timeSlotStart, unavailablePeriods, appointmentLength) {
        return unavailablePeriods.every((unavailablePeriod) => {
            const timeSlotEnd = this.getAppointmentEndDate(
                    timeSlotStart,
                    appointmentLength
                ),
                unavailablePeriodEnd = this.getAppointmentEndDate(
                    unavailablePeriod.startTime,
                    unavailablePeriod.isTourNow
                        ? appointmentLength * 2
                        : appointmentLength
                );

            if (
                isEqual(timeSlotStart, unavailablePeriodEnd) ||
                isEqual(timeSlotEnd, unavailablePeriod.startTime)
            ) {
                return true;
            }

            const unavailableInterval = {
                    start: unavailablePeriod.startTime,
                    end: unavailablePeriodEnd
                },
                startAvailable = !isWithinInterval(
                    timeSlotStart,
                    unavailableInterval
                ),
                endAvailable = !isWithinInterval(
                    timeSlotEnd,
                    unavailableInterval
                );

            return startAvailable && endAvailable;
        });
    }

    /**
     * Returns upcoming days for which appointment time slots can be booked.
     *
     * @type {[Date]}
     */
    @computed('args.model.timeZone', 'days', 'mockTime.mockTime')
    get appointmentDays() {
        const { timeZone } = this.args.model || {};
        const currentUnitDateLocal = new Date(
            new Date().toLocaleString('en-US', { timeZone: timeZone })
        );
        const today = startOfDay(
            this.mockTime.mockTime ?? currentUnitDateLocal
        );
        let daySlots = Array.from(new Array(this.days), (_, index) =>
            addDays(today, index)
        );

        // eslint-disable-next-line ember/no-side-effects
        set(this, 'selectedDate', daySlots[0]);
        return daySlots;
    }

    /**
     * Returns the NextAppointment's dateTimeUtc in local time. The dateTimeLocal property on the appointment model
     * is a date time object adjusted to the system's local timezone.
     *
     * @type {[Date]}
     */
    @computed('args.model.nextAppointment')
    get nextAppointmentLocalTime() {
        return (async () => {
            const nextAppointment = await this.args.model.nextAppointment;
            return nextAppointment.dateTimeLocal;
        })();
    }

    /**
     * Determines if the start time should be changed to 'now'.
     *
     * @returns {Boolean}
     */
    @computed('args.model.nextAppointment')
    get shouldDisplayNow() {
        return (async () => {
            // Compare the dateTimeUtc to the current time. The value of dateTimeUtc is automatically
            // converted from UTC to the time zone of the browser.
            const nextAppointment = await this.args.model.nextAppointment;
            return new Date() >= nextAppointment.dateTimeUtc;
        })();
    }

    /**
     * Returns the NextAppointment's end dateTimeUtc in local time. The dateTimeLocal property on the appointment model
     * is a date time object adjusted to the system's local timezone.
     *
     * @type {[Date]}
     */
    @computed(
        'nextAppointmentLocalTime',
        'args.{appointmentSettings.appointmentLength,model.nextAppointment}'
    )
    get nextAppointmentLocalEndTime() {
        return (async () => {
            const appointmentTime = await this.nextAppointmentLocalTime,
                appointmentEndTime = new Date(appointmentTime),
                nextAppointment = await this.args.model.nextAppointment;
            let length = nextAppointment.length;

            return new Date(
                appointmentEndTime.setMinutes(
                    appointmentTime.getMinutes() + length
                )
            );
        })();
    }

    /**
     * Returns objects required to build appointment time slot rows.
     *
     * @type {Array<{startDateTime: {Date}, available: {Boolean}}>}
     */
    @computed(
        'args.{appointmentSettings,model.timeZone,model.appointmentTimeSlotsFromCrmLocal}',
        'mockTime.mockTime',
        'selectedDate',
        'unavailablePeriods.[]'
    )
    get appointmentTimeSlots() {
        return (async () => {
            const { timeZone } = await this.args.model,
                unavailablePeriodModelsUTC = await this.unavailablePeriods,
                { appointmentLength } = this.args.appointmentSettings,
                currentUnitDateLocal = new Date(
                    new Date().toLocaleString('en-US', { timeZone: timeZone })
                ),
                timeSlotsLocal = this.getTimeSlotsLocal(
                    this.mockTime.mockTime ?? currentUnitDateLocal
                );

            // Get location's unavailable times.
            const unavailablePeriodsLocal = unavailablePeriodModelsUTC.map(
                (model) => {
                    const dateTimeUTC = new Date(model.dateTimeUtc),
                        dateTimeLocal = utcToZonedTime(dateTimeUTC, timeZone),
                        isTourNow = model.isTourNow;
                    return {
                        startTime: dateTimeLocal,
                        isTourNow: isTourNow
                    };
                }
            );

            // Set time slots which are unavailable for appointment.
            timeSlotsLocal
                .filter((timeSlot) => {
                    return !this.isTimeSlotAvailable(
                        timeSlot.startDateTime,
                        unavailablePeriodsLocal,
                        appointmentLength
                    );
                })
                .forEach((t) => set(t, 'available', false));

            // If we have at least one time slot available, mark it as TOUR NOW.
            if (timeSlotsLocal.length > 0) {
                if (
                    isAfter(
                        this.mockTime.mockTime ?? currentUnitDateLocal,
                        timeSlotsLocal[0].startDateTime
                    )
                ) {
                    timeSlotsLocal[0].currentTimeSlot = true;
                }

                // If the first time slot is current and the second is unavailable, mark the first as unavailable as well.
                if (timeSlotsLocal.length > 1) {
                    if (
                        !timeSlotsLocal[1].available &&
                        timeSlotsLocal[0].currentTimeSlot
                    ) {
                        timeSlotsLocal[0].available = false;
                    }
                }
            }

            return timeSlotsLocal;
        })();
    }

    @computed('appointmentTimeSlots')
    get hasFutureAppointmentSlots() {
        return (async () => {
            const slots = await this.appointmentTimeSlots;
            return slots.length > 0;
        })();
    }

    /**
     * Returns the time slots for display, with past slots filtered out.
     */
    getTimeSlotsLocal(currentUnitDateLocal) {
        let timeSlotsLocal;

        // If the property is not getting their available times from a CRM integration, generate them.
        if (this.args.model.appointmentTimeSlotsFromCrmLocal == undefined) {
            timeSlotsLocal = this.generateTimeSlotsForDay(currentUnitDateLocal);
        } else {
            timeSlotsLocal = this.getTimeSlotsForCrmLocal(currentUnitDateLocal);
        }

        return timeSlotsLocal.filter((slot) => slot.available);
    }

    /**
     * Generates time slots for the given date using the hours of operation schedule.
     */
    generateTimeSlotsForDay(currentUnitDateLocal) {
        const { appointmentLength, hoursOfOperationSchedule } =
                this.args.appointmentSettings,
            targetDay = this.selectedDate;

        if (!targetDay) {
            return [];
        }

        // Get the timeblocks for the selected day.
        const filteredTimeblocks = hoursOfOperationSchedule.filter(
                (tb) => tb.day === targetDay.getDay()
            ),
            appointments = [];

        // Iterate through the timeblocks and add appointment slots.
        filteredTimeblocks.forEach(({ startMinutesLocal, endMinutesLocal }) => {
            for (
                let currentTime = startMinutesLocal;
                currentTime <= endMinutesLocal;
                currentTime += appointmentLength
            ) {
                const startDateTime = new Date(targetDay);
                startDateTime.setMinutes(currentTime);
                const endDateTime = this.getAppointmentEndDate(
                    new Date(startDateTime),
                    appointmentLength
                );
                appointments.push({
                    startDateTime,
                    available: isAfter(endDateTime, currentUnitDateLocal),
                    currentTimeSlot: false
                });
            }
        });

        return appointments;
    }

    /**
     * Filters down and returns the time slots for the given date, only for properties with CRM integrations.
     */
    getTimeSlotsForCrmLocal(currentUnitDateLocal) {
        const timeSlots = [];

        this.args.model.appointmentTimeSlotsFromCrmLocal.forEach((slot) => {
            const startDateLocal = new Date(slot);
            const endDateLocal = this.getAppointmentEndDate(
                startDateLocal,
                this.args.appointmentSettings.appointmentLength
            );

            // Time slot needs to be for the selected date and the end date needs to be past right now.
            if (
                !datesAreOnSameDay(startDateLocal, this.selectedDate) ||
                !isAfter(endDateLocal, currentUnitDateLocal)
            ) {
                return;
            }

            // Since CRM time slots do not come with an end time, we need to make sure they don't overlap.
            const previousSlot = timeSlots[timeSlots.length - 1];
            if (previousSlot?.endDateTime > startDateLocal) {
                return;
            }

            timeSlots.push({
                startDateTime: startDateLocal,
                endDateTime: endDateLocal,
                available: true,
                currentTimeSlot: false
            });
        });

        return timeSlots;
    }

    /**
     * Open terms and conditions modal.
     *
     * @param {Date} startDateTime
     */
    @action openTermsAndConditionsModal(startDateTime) {
        this.modals.showModal('components/modals/terms-and-conditions', {
            saveAction: () => this.args.saveAction(startDateTime),
            context: this.args.context
        });
    }
}
