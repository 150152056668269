import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'unattended-showing/config/environment';

// Style imports for webpack
import './assets/styles/app.scss';

// Imports for v2 addon styles
import '@adc/ui-components/addon.scss';

export default class App extends Application {
    modulePrefix = config.modulePrefix;
    podModulePrefix = config.podModulePrefix;
    Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
