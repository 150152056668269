import Route from '@ember/routing/route';
import Context from '../../models/context';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';

interface ContextModel {
    context: Promise<Context>;
}

/**
 * @classdesc URL is /bookings-sgt3/ but we use AvailableUnitsList in the code.
 *
 * @class availableUnitsListSgt3.AvailableUnitsListSgt3Route
 * @extends Route
 *
 */
export default class AvailableUnitsListSgt3Route extends Route {
    @service declare store: ServiceRegistry['store'];
    @service declare router: ServiceRegistry['router'];

    /**
     * @param model
     * @returns {{availableUnitsList: (models.availableUnitsList|promise), context: (models.context|promise)}}
     */
    async model(model: { available_units_list_id: string | number }) {
        try {
            const contextModel = this.modelFor('context') as ContextModel;
            const context = await contextModel.context;
            const availableUnitsList = await this.store.findRecord(
                'available-units-list',
                model.available_units_list_id
            );

            return {
                availableUnitsList,
                context
            };
        } catch (error) {
            this.send('error', error);

            return {};
        }
    }

    /**
     * @param model
     * redirects to not-found page if context does not have SGT3 toggle.
     */
    async afterModel(model: { context: Context }) {
        if (!model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
