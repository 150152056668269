import Transform from '@ember-data/serializer/transform';
import { A } from '@ember/array';

export default class HoursOfOperation extends Transform {
    deserialize(scheduleDictionary) {
        return A(
            Object.keys(scheduleDictionary || {}).map((scheduleDay) => {
                const { startMinutesLocal, durationMinutes, day } =
                    scheduleDictionary[Number(scheduleDay)];

                return {
                    day,
                    startMinutesLocal,
                    endMinutesLocal: startMinutesLocal + durationMinutes
                };
            })
        );
    }

    serialize(schedule) {
        return schedule.reduce(
            (
                scheduleDictionary,
                { day, startMinutesLocal, endMinutesLocal },
                index
            ) => {
                scheduleDictionary[index] = {
                    day,
                    startMinutesLocal,
                    durationMinutes: endMinutesLocal - startMinutesLocal
                };
                return scheduleDictionary;
            },
            {}
        );
    }
}
