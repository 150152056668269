import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * @classdesc
 * The main application route.
 */
export default class ApplicationRoute extends Route {
    @service notificationManager;
    @service session;
    @service errorReporting;
    @service store;
    @service intl;
    @service router;

    async beforeModel() {
        this.intl.setLocale('en-US');
        await this.session.setup();
    }

    /** @override **/
    afterModel() {
        // Start Sentry Error Reporting
        this.errorReporting.initializeForSession();
    }

    /**
     * Error handler for the application.
     *
     * @param errors {Object}
     */
    @action error(errors) {
        let errorMessages = errors?.errors?.errors ?? errors?.errors ?? [];

        // Make sure errorMessages is an array.
        if (typeof errorMessages === 'string') {
            errorMessages = [errorMessages];
        }

        // Show the error message and then let the error reporting service handle it.
        errorMessages.forEach((error) =>
            this.notificationManager.addError(error)
        );

        // Redirect to error page.
        this.router.replaceWith('not-found', 'not-found');

        // Let error reporting report the error.
        throw errors;
    }
}
