import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { getCommunitySiteMapObjectUrl } from 'unattended-showing/helpers/file-utils';

/**
 * @classdesc
 * Controller for Self-Guided Tours availableUnitsList (bookings) page.
 */
export default class AvailableUnitsListController extends Controller {
    @service ajax;

    @tracked currentId = null;

    /**
     * Redirect user to the scheduling page for the selected location
     *
     * @function
     */
    @action redirectAction() {
        if (this.currentId != null) {
            var locationUrl = 'location/' + String(this.currentId);
            window.open(
                String(window.location).replace(/bookings.*/, locationUrl)
            );
        }
    }

    /**
     * Sets the id of the unit that is selected
     *
     * @function
     * @param {models.availableUnitsList.locations.id} locationId
     */
    @action selectLocation(locationId) {
        this.currentId = locationId;
    }

    /**
     * Opens a new tab for the community site map.
     *
     * @function
     */
    @action async openCommunitySiteMap(ev) {
        ev.preventDefault();

        await getCommunitySiteMapObjectUrl(
            this.model.context,
            this.model.context.systemGroupId,
            this.ajax
        );

        window.open(
            this.model.context.communitySiteMapObjectUrls[
                this.model.context.systemGroupId
            ],
            '_blank',
            'noopener noreferrer'
        );
    }
}
