import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ApplicationAdapter from '../../adapters/application';

/**
 * @classdesc
 * Controller for sending password reset requests.
 */
export default class ForgotPasswordController extends Controller {
    @service ajax;
    @service notificationManager;

    /**
     * Submits password reset request.
     */
    @action async submit() {
        try {
            const { email, notificationManager } = this;

            // Send password reset request.
            const response = await this.ajax.request(
                this.ajax.apiBaseUrl + '/login/forgotPassword',
                {
                    body: JSON.stringify({
                        email: email
                    }),
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, same-origin, *omit
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'same-origin', // no-cors, cors, *same-origin
                    redirect: 'follow', // manual, *follow, error
                    referrer: window.location.href // *client, no-referrer
                }
            );

            notificationManager.addSuccess(response);
        } catch (exception) {
            // Normalize errors.
            const adapter = ApplicationAdapter.create({});
            const errors = {
                errors: adapter.normalizeErrorResponse(0, '', exception)
            };

            (
                errors.errors || [
                    'Unable to request password reset. Please try again later.'
                ]
            ).forEach((error) => this.notificationManager.addError(error));

            return Promise.reject(errors.errors);
        }
    }
}
