import ProtectedRoute from 'unattended-showing/classes/protected-route';
import { inject as service } from '@ember/service';

/**
 * @classdesc
 * Verification sub-route for user sign-up.
 */
export default class SignUpVerificationRoute extends ProtectedRoute {
    @service store;

    /**
     * Creates new credit card verification model.
     *
     * @returns {{creditCardVerification: (models.CreditCardVerification|promise)}}
     */
    async model() {
        const countries = await this.store.findAll('country');
        return {
            creditCardVerification: this.store.createRecord(
                'credit-card-verification'
            ),
            countries
        };
    }

    /**
     * Deletes created model if not saving when exiting route.
     */
    async deactivate() {
        const creditCardVerificationModel = await this.currentModel
            .creditCardVerification;
        creditCardVerificationModel.unloadRecord();
    }
}
