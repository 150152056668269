import Model, { belongsTo } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing new user's signup information.
 */
export default class SignUpModel extends Model {
    /**
     * New user's profile
     *
     * @type {Profile}
     */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/require-async-inverse-relationship
    @belongsTo('profile', { async: true })
    profile;

    /**
     * New user's password.
     *
     * @type {ProfilePassword}
     */
    @belongsTo('profile-password', { async: true, inverse: null })
    password;
}
