import Route from '@ember/routing/route';
import Context from '../../models/context';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';

/**
 * @classdesc Route for login SGT3 route.
 */
export default class LoginSgt3Route extends Route {
    @service declare router: ServiceRegistry['router'];

    /**
     * @param model
     * redirects to not-found page if context does not have SGT3 toggle.
     */
    async afterModel(model: { context: Context }) {
        if (!model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
