/**
 * Utility helper module for validating inputs.
 * @module
 */

/**
 * Takes a Regex and an input and checks if the input with Regex selected characters removed is greater than 0.
 *
 * EXAMPLE: Passing /\D/g as regEx and '123456' as input will return true.
 *
 * @function
 *
 * @param {RegExp} regEx
 * @param {String} input
 * @returns {Boolean}
 */
export function validateCustomRegex(regEx, input) {
    return regEx.test(input.toString());
}

/**
 * Takes an email, as a string, for emailInput and checks if it meets the RFC5322 standard (https://www.ietf.org/rfc/rfc5322.txt).
 *
 * @function
 *
 * @param {String} emailInput
 * @returns {Boolean}
 */
export function validateEmail(emailInput) {
    return /^((([!#$%'*+\-/0-9=?A-Z^_'a-z{|}~]{1,})|("([^"\r\n]|(\\[.\n\r]))*"))(\.(([!#$%'*+\-/0-9=?A-Z^_'a-z{|}~]{1,})|("([^"\r\n]|(\\[.\n\r]))*")))*)@(([-0-9a-zA-Z]{1,})(\.([-0-9a-zA-Z]{1,})){1,})$/.test(
        emailInput.toString()
    );
}

/**
 * Takes a password, as a string, for passwordInput and checks if it has at least 1 number, 8 or more characters, and at least 1 uppercase character.
 *
 * @function
 *
 * @param {String} passwordInput
 * @returns {Boolean}
 */
export function validatePassword(passwordInput) {
    return (
        passwordInput.toString().replace(/\D/g, '').length > 0 &&
        passwordInput.toString().replace(/\s/g, '').length >= 8 &&
        passwordInput.toString().replace(/[^A-Z]/g, '').length > 0
    );
}

/**
 * Takes a Regex and an input and checks if the input with Regex selected characters removed is greater than 0.
 * Also returns true for empty and undefined inputs (to not display error messages on input boxes for empty inputs).
 *
 * EXAMPLE: Passing /\D/g as regEx and '123456' as input will return true.
 * EXAMPLE: Passing /\s/g as regEx and '' as input will return true.
 *
 * @function
 *
 * @param {RegExp} regEx
 * @param {String} input
 * @returns {Boolean}
 */
export function validateTextInputCustomRegex(regEx, input) {
    return (
        input === undefined || input === '' || validateCustomRegex(regEx, input)
    );
}

/**
 * Takes an email, as a string, for emailInput and checks if it meets the RFC5322 standard (https://www.ietf.org/rfc/rfc5322.txt).
 * Also returns true for empty and undefined inputs (to not display error messages on input boxes for empty inputs).
 *
 * @function
 *
 * @param {String} emailInput
 * @returns {Boolean}
 */
export function validateTextInputEmail(emailInput) {
    return (
        emailInput === undefined ||
        emailInput === '' ||
        validateEmail(emailInput)
    );
}

/**
 * Takes a password, as a string, for passwordInput and checks if it has at least 1 number, 8 or more characters, and at least 1 uppercase character.
 * Also returns true for empty and undefined inputs (to not display error messages on input boxes for empty inputs).
 *
 * @function
 *
 * @param {String} passwordInput
 * @returns {Boolean}
 */
export function validateTextInputPassword(passwordInput) {
    return (
        passwordInput === undefined ||
        passwordInput === '' ||
        validatePassword(passwordInput)
    );
}
