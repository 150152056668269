import Controller from '@ember/controller';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
    createRowItem,
    createNativeSubField
} from '../../../components/input-form-row/component';
import {
    validateTextInputPassword,
    validateTextInputEmail,
    validateTextInputCustomRegex
} from '../../../utils/validators';

/**
 * Controller for personal-information sign-up sub-route.
 */
export default class SignUpPersonalInformationController extends Controller {
    @service session;
    @service cookies;
    @service store;
    @service router;
    @service ajax;
    @service loginPendingBooking;

    /**
     * Response message from server requests.
     *
     * @type {String}
     * @default ''
     */
    requestMessage = '';

    /**
     * Returns object for country data
     *
     * @function
     * @returns {Array<{zipValidator: {Function}}>}
     */
    @computed('model.countries')
    get zipValidators() {
        return this.model.countries.map((country) =>
            validateTextInputCustomRegex.bind(
                null,
                new RegExp('^' + country.zipValidator + '$')
            )
        );
    }

    /**
     * Returns fields needed for template to generate sign up fields for user's personal information.
     *
     * @function
     * @returns {Array<{label: {String}, subFields: Array<{inputType: {String}, propertyName: {String}}>, validator: {Function}, errorMessage: {String}}>}
     */
    @computed('zipValidators')
    get userInfoFields() {
        const fnTextValidate = validateTextInputCustomRegex.bind(
            null,
            /[a-zA-Z]+/
        );
        const fnNumberValidate = validateTextInputCustomRegex.bind(
            null,
            /^[0-9]+$/
        );
        const postalCodeValidate = (input) =>
            this.zipValidators.some((validator) => validator(input));

        return [
            createRowItem(
                'First name',
                [createNativeSubField('firstName')],
                fnTextValidate,
                'Please enter your first name.'
            ),
            createRowItem(
                'Last name',
                [createNativeSubField('lastName')],
                fnTextValidate,
                'Please enter your last name.'
            ),
            createRowItem(
                'Email',
                [createNativeSubField('email')],
                validateTextInputEmail,
                'Please enter a valid email.'
            ),
            createRowItem(
                'Mobile number',
                [createNativeSubField('mobileNumber', 'text', 11)],
                fnNumberValidate,
                'Please enter a valid mobile number (include area code).'
            ),
            createRowItem(
                'Postal code',
                [createNativeSubField('zipCode', 'text', 6)],
                postalCodeValidate,
                'Please enter a valid postal code.'
            )
        ];
    }

    /**
     * Returns fields needed for template to generate sign up fields for user's password.
     *
     * @function
     * @returns {Array<{label: {String}, subFields: Array<{inputType: {String}, propertyName: {String}}>, validator: {Function}, errorMessage: {String}}>}
     */
    get userPasswordFields() {
        return [
            createRowItem(
                'Password',
                [createNativeSubField('password', 'password')],
                validateTextInputPassword,
                'Password must contain at least eight characters, one number (0-9), and one capital letter.'
            ),
            createRowItem(
                'Confirm password',
                [createNativeSubField('confirmPassword', 'password')],
                validateTextInputPassword,
                'Please enter the same password again.'
            )
        ];
    }

    /**
     * Save new user profile.
     *
     * @param {models.SignUp} model
     * @returns {models.SignUp|promise}
     */
    @action async save(model) {
        const signUp = await model.signUp,
            profile = await model.signUp.profile,
            password = await model.signUp.password,
            { cookies, router } = this;

        // Save user profile and then transition to credit card verification route.
        try {
            await signUp.save();

            // Cache and restore redirect URL cookie value because authentication call will wipe it.
            const redirectUrl = this.cookies.read(
                'ember_simple_auth-redirectTarget'
            );

            await this.session.authenticate(
                'authenticator:credentials',
                profile.email,
                password.password
            );

            // If the redirect cookie was set before the authenticate call, then restore it.
            if (redirectUrl) {
                cookies.write('ember_simple_auth-redirectTarget', redirectUrl);
            }

            // If the dealer requires credit card verification, redirect to cc verification
            // Else if the user has a pending appointment (appointment selected prior to logging in),
            // then that appointment should be automatically booked.
            // Otherwise, redirect user to the saved cookie, if it exists.
            const context = await this.store.peekAll('context').objectAt(0);
            if (context.showCCPage) {
                router.transitionTo('context.sign-up.verification', context.id);
            } else if (this.loginPendingBooking.hasPendingAppointments) {
                await this.loginPendingBooking.processPendingAppointments();
            } else {
                // eslint-disable-next-line ember/no-array-prototype-extensions
                cookies.clear('ember_simple_auth-redirectTarget');
                router.transitionTo(
                    redirectUrl || 'context.appointment',
                    context.id
                );
            }
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable sign up new user.'
            );
        }
    }
}
