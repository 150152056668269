import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

/**
 * @classdesc
 * Model for storing user profile information.
 */
export default class ProfileModel extends Model {
    /**
     * First name of user.
     *
     * @type {String}
     */
    @attr('string')
    firstName;

    /**
     * Last name of user.
     *
     * @type {String}
     */
    @attr('string')
    lastName;

    /**
     * Email of user.
     *
     * @type {String}
     */
    @attr('string')
    email;

    /**
     * Mobile phone number of user.
     *
     * @type {number}
     */
    @attr('number')
    mobileNumber;

    /**
     * Zip code of user.
     *
     * @type {Number}
     */
    @attr('string')
    zipCode;

    /**
     * User profile creation date.
     *
     * @type {Date}
     */
    @attr('date')
    createdAt;

    /**
     * User profile last updated date.
     *
     * @type {Date}
     */
    @attr('date')
    updatedAt;

    /**
     * Whether a forced password reset is required.
     *
     * @type {Boolean}
     */
    @attr('boolean', { defaultValue: false })
    forcePasswordReset;

    /**
     * Whether user's credit card has been validated.
     *
     * @type {Boolean}
     */
    @attr('boolean', { defaultValue: false })
    creditCardValidated;

    /**
     * Return full name of user.
     *
     * @type {String}
     */
    @computed('firstName', 'lastName')
    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
}
