import Model, { attr } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing countries Self-Guided Tours is available in.
 */
export default class Country extends Model {
    /**
     * Name of country
     *
     * @type {String}
     */
    @attr('string')
    countryName;

    /**
     * Array of states for country
     *
     * @type {Array<String>}
     */
    @attr()
    states;

    /**
     * Name of "Zip" type field for country
     *
     * @type {String}
     */
    @attr('string')
    zipFieldName;

    /**
     * Name of "State" type field for country
     *
     * @type {String}
     */
    @attr('string')
    stateFieldName;

    /**
     * Required length for zip/postal code for country
     *
     * @type {Number}
     */
    @attr('number')
    zipLength;

    /**
     * Regex, as a string, for zip/postal code validation
     *
     * @type {String}
     */
    @attr('string')
    zipValidator;
}
