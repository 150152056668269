import Model, { attr } from '@ember-data/model';

/**
 * Model for storing credit card validation information.
 */
export default class CreditCardVerification extends Model {
    /**
     * First name on credit card.
     *
     * @type {String}
     */
    @attr('string')
    firstName;

    /**
     * Last name on credit card.
     *
     * @type {String}
     */
    @attr('string')
    lastName;

    /**
     * Credit card number.
     *
     * @type {Number}
     */
    @attr('number')
    cardNumber;

    /**
     * Credit card expiration month in MM.
     *
     * @type {Number}
     */
    @attr('number')
    expirationMonth;

    /**
     * Credit card expiration year in YYYY.
     *
     * @type {Number}
     */
    @attr('number')
    expirationYear;

    /**
     * Credit card security code.
     *
     * @type {Number}
     */
    @attr('number')
    securityCode;

    /**
     * Credit card billing street address.
     *
     * @type {String}
     */
    @attr('string')
    address;

    /**
     * Credit card billing address city.
     *
     * @type {String}
     */
    @attr('string')
    city;

    /**
     * Credit card billing address state.
     *
     * @type {String}
     */
    @attr('string')
    state;

    /**
     * Credit card billing address zip code.
     *
     * @type {Number}
     */
    @attr('string')
    zipCode;

    /**
     * Credit card billing address country.
     *
     * @type {String}
     */
    @attr('number', { defaultValue: 0 })
    country;
}
