import { computed } from '@ember/object';
import { attr, hasMany } from '@ember-data/model';
import BaseLocation from './base-location';
import SanitizedAppointment from './sanitized-appointment';

import type { AsyncHasMany } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing Self-Guided Tours property information.
 */
export default class LocationModel extends BaseLocation {
    /**
     * Number of bedrooms for Self-Guided Tours property.
     */
    @attr('number') declare numberOfBedrooms: number;

    /**
     * Number of bathrooms for Self-Guided Tours property.
     */
    @attr('number') declare numberOfBathrooms: number;

    /**
     * Square footage of Self-Guided Tours property.
     */
    @attr('number') declare squareFootage: number;

    /**
     * Rent for Self-Guided Tours property.
     */
    @attr('number') declare rent: number;

    /**
     * Localized string for rent or price
     */
    @attr('string') declare rentOrPriceString: string;

    /**
     * Unit name
     */
    @attr('string') declare unitDescription: string;

    /**
     * Creation date for Self-Guided Tours property record.
     */
    @attr('date') declare createdAt: Date;

    /**
     * Last updated date for Self-Guided Tours property record.
     */
    @attr('date') declare updatedAt: Date;

    /**
     * Appointment slots for property that are unavailable.
     */
    @hasMany('sanitized-appointment', {
        inverse: 'location',
        async: true
    })
    declare unavailablePeriods: AsyncHasMany<SanitizedAppointment>;

    /**
     * Appointment slots retrieved from the CRM integration if the property is integrated.
     */
    @attr() declare appointmentTimeSlotsFromCrmLocal: any;

    /**
     * Whether the location's community has a site map.
     */
    @attr('boolean') declare hasCommunitySiteMap: boolean;

    /**
     * Returns property details along with its label.
     */
    @computed(
        'formattedRent',
        'numberOfBathrooms',
        'numberOfBedrooms',
        'rent',
        'rentOrPriceString',
        'squareFootage'
    )
    get availableDetails(): { title: string; value: string }[] {
        return [
            ['Beds', this.numberOfBedrooms.toLocaleString()],
            ['Baths', this.numberOfBathrooms.toLocaleString()],
            ['Sq. Ft.', this.squareFootage.toLocaleString()],
            [this.rentOrPriceString, this.formattedRent]
        ]
            .map(([title, value]) => ({ title, value }))
            .filter((attribute) => attribute.value);
    }

    /**
     * Returns the locations rent as a formatted string.
     */
    @computed('rent')
    get formattedRent(): string {
        if (!this.rent) {
            return '-';
        }

        const wholeNumberRent = Math.trunc(this.rent);
        return `$${wholeNumberRent.toLocaleString()}`;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        location: LocationModel;
    }
}
