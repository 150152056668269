/**
 * Returns true if the two dates are on the same day, else false.
 * @param {{date1: Date}} date1 First date to compare.
 * @param {{date2: Date}} date2 Second date to compare.
 * @returns a boolean based on if the dates are on the same day.
 */
export function datesAreOnSameDay(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}
