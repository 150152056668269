import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

import type Location from './location';
import type Context from './context';
import type { AsyncBelongsTo } from '@ember-data/model';

/**
 * @classdesc
 * Model for user appointment data.
 */
export default class Appointment extends Model {
    /**
     * Date appointment was created.
     */
    @attr('date') declare createdAt: Date;

    /**
     * Date appointment was updated.
     */
    @attr('date') declare updatedAt: Date;

    /**
     * Appointment lock code.
     */
    @attr('string') declare userCode: string;

    /**
     * Code for onsite non-ADC integrated gate.
     */
    @attr('string') declare gateCode: string;

    /**
     * Appointment date and time. The dateTimeUtc passed from the backend should be in UTC.
     * Note: This time will be converted to the browser's local time.
     */
    @attr('date') declare dateTimeUtc: Date;

    /**
     * Appointment date and time in system's local timezone as a string for displaying.
     * The `dateTimeLocal` property creates a date time object from this string, so use
     * that object when displaying the local date time of an appointment for the end-user.
     */
    @attr('string') declare localAppointmentStartTime: string;

    /**
     * Whether the appointment is an upcoming appointment.
     */
    @attr('boolean') declare activePendingIdVerification: boolean;

    /**
     * Appointment location.
     */
    @belongsTo('location', { async: true, inverse: null })
    declare location: AsyncBelongsTo<Location>;

    /**
     * Appointment's dealer.
     */
    @belongsTo('context', { async: true, inverse: null })
    declare context: AsyncBelongsTo<Context>;

    /**
     * Whether the appointment is a current appointment (i.e. not expired).
     */
    @attr('boolean') declare current: boolean;

    /**
     * Whether the appointment is a past appointment (i.e. completed successfully).
     */
    @attr('boolean') declare past: boolean;

    /**
     * Returns street address of associated location.
     */
    @alias('location.streetAddress')
    declare streetAddress: Location['streetAddress'];

    /**
     * Returns region of associated location.
     */
    @alias('location.region') declare region: Location['region'];

    /**
     * Returns full address of associated location.
     */
    @alias('location.fullAddress') declare fullAddress: Location['fullAddress'];

    /**
     * Appointment subdomain.
     */
    @attr('string') declare subdomain: string;

    /**
     * Whether the check-in button should be displayed.
     */
    @attr('boolean') declare showCheckIn: boolean;

    /**
     * Returns the timezone of the appointment.
     */
    @computed('location.timeZone')
    get timeZone(): string {
        // DO NOT COPY THIS, PLEASE IMPLEMENT THE SUGGESTION AND TEST.
        // @ts-expect-error `location` is async so we have to await it before we query it's properties.
        return this.location.timeZone;
    }

    /**
     * Local appointment date and time.
     */
    @computed('localAppointmentStartTime')
    get dateTimeLocal(): Date {
        return new Date(this.localAppointmentStartTime);
    }

    /**
     * Appointment length.
     */
    @attr('number') declare length: number;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        appointment: Appointment;
    }
}
