import BaseLocation from './base-location';
import { hasMany } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing Self-Guided Tours availableUnitsList (Single Link Showings) information.
 */
export default class AvailableUnitsList extends BaseLocation {
    /**
     * List of properties in this Self-Guided Tours group.
     *
     * @type {model.Location[]}
     */
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/require-async-inverse-relationship
    @hasMany('location', { async: true })
    locations;
}
