import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * @classdesc Route for Self-Guided Tours Single Link Showings. URL is /bookings/ but we use AvailableUnitsList in the code.
 *
 * @class availableUnitsList.AvailableUnitsListRoute
 * @extends Route
 *
 */
export default class AvailableUnitsListRoute extends Route {
    /**
     * Inject notification manager to show messages.
     */
    @service notificationManager;
    @service store;
    @service cookies;

    /**
     * @param model
     * @returns {{availableUnitsList: (models.availableUnitsList|promise), context: (models.context|promise)}}
     */
    async model(model) {
        try {
            const context = this.modelFor('context').context;
            const availableUnitsList = await this.store.findRecord(
                'available-units-list',
                model.available_units_list_id
            );

            // Construct availableUnitsList Url to restore if the user signs up,
            // but only store it if it is valid (both of the values below exist)
            const validUrl =
                context.id != null && availableUnitsList.id != null;

            if (validUrl) {
                const redirectUrl =
                    '/' + context.id + '/bookings/' + availableUnitsList.id;
                this.cookies.write(
                    'ember_simple_auth-redirectTarget',
                    redirectUrl
                );
            }

            return {
                availableUnitsList,
                context
            };
        } catch (error) {
            this.send('error', error);
        }
    }
}
