import Base from 'ember-simple-auth/authenticators/base';
import { inject as service } from '@ember/service';
import ApplicationAdapter from '../adapters/application';

/**
 * @classdesc
 * Authenticator for Self-Guided Tours login.
 */
export default class CredentialsAuthenticator extends Base {
    @service ajax;

    /** @override **/
    async restore(data) {
        return Promise.resolve(data);
    }

    /** @override **/
    async authenticate(username, password) {
        let response;
        const adapter = ApplicationAdapter.create({});

        try {
            response = await this.ajax.request(
                this.ajax.apiBaseUrl + '/login',
                {
                    body: JSON.stringify({
                        username: username,
                        password: password
                    }),
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, same-origin, *omit
                    headers: {
                        'content-type': 'application/json'
                    },
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'same-origin', // no-cors, cors, *same-origin
                    redirect: 'follow', // manual, *follow, error
                    referrer: window.location.href // *client, no-referrer
                }
            );
        } catch (exception) {
            response = {
                value: undefined,
                errors: adapter.normalizeErrorResponse(0, '', exception)
            };
        }

        // Get data in response from backend authentication request.
        const { value } = response;

        // Throw the error if response doesn't contain value expected.
        if (!value) {
            throw {
                errors: response.errors || [
                    'Unable to authenticate user. Please try again later.'
                ]
            };
        }

        // These values are expected by the application once the user is logged in.
        return {
            profileId: value.id,
            token: value.token
        };
    }
}
