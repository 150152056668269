import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { computed, action } from '@ember/object';

/**
 * Component for application header bar.
 */
export default class AppHeader extends Component {
    @service session;

    /**
     * Dealer logo's URL.
     *
     * @type {String}
     */
    get serverLogoUrl() {
        return this.args.serverLogoUrl ?? '';
    }

    /**
     * Whether menu items are collapsed in narrow windows.
     *
     * @type {Boolean}
     * @default true
     */
    @tracked isCollapsed = true;

    /**
     * Get the dealer logo's URL.
     *
     * @function
     * @returns {String}
     */
    @computed('serverLogoUrl')
    get logoUrl() {
        // If no dealer logo is provided, just show the PointCentral logo.
        return (
            this.serverLogoUrl ||
            'https://alarmadmin.alarm.com//api/PublishedImage.ashx?guid=C7E50E3E-5E8C-4FC2-9F8F-042FA9A52572'
        );
    }

    /**
     * Get the logged-in user's name.
     *
     * @function
     * @returns {String}
     */
    @computed('session.{data.authenticated.userName,isAuthenticated}')
    get username() {
        return this.session.isAuthenticated
            ? this.session.data.authenticated.userName
            : 'Login';
    }

    /**
     * Set menu to collapsed state when user clicks on something.
     *
     * @function
     */
    @action handleClick() {
        this.isCollapsed = true;
    }

    /**
     * Toggles collapsed state.
     *
     * @function
     */
    @action toggleMenu() {
        this.isCollapsed = !this.isCollapsed;
    }
}
