import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Route for unattended property locations.
 */
export default class LocationRoute extends Route {
    @service store;
    @service cookies;

    /**
     * @param model
     * @returns {{location: (models.location|promise), context: (models.context|promise)}}
     */
    async model(model) {
        try {
            const context = this.modelFor('context').context;
            const location = await this.store.findRecord(
                'location',
                model.location_id,
                {
                    adapterOptions: {
                        queryParams: { systemGroupId: context.systemGroupId }
                    }
                }
            );

            // Construct this location's Url to restore if the user signs up,
            // but only store it if it is valid (both of the values below exist)
            const validUrl = context.id != null && location.id != null;

            if (validUrl) {
                const redirectUrl =
                    '/' + context.id + '/location/' + location.id;
                this.cookies.write(
                    'ember_simple_auth-redirectTarget',
                    redirectUrl
                );
            }

            return {
                location,
                context
            };
        } catch (error) {
            this.send('error', error);
        }
    }
}
