import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Route for password reset.
 */
export default class ForgotPasswordRoute extends Route {
    @service session;

    beforeModel() {
        this.session.prohibitAuthentication('context.appointment');
    }
}
