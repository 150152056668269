import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { subMinutes } from 'date-fns';

import type Appointment from 'unattended-showing/models/appointment';
import type LocationModel from 'unattended-showing/models/location';
import type CheckInService from './check-in';
import type AjaxService from './ajax';
import type { Registry as ServiceRegistry } from '@ember/service';
import type { AsyncBelongsTo } from '@ember-data/model';

export default class LoginPendingBookingService extends Service {
    @service declare router: ServiceRegistry['router'];
    @service declare ajax: AjaxService;
    @service declare checkIn: CheckInService;

    /**
     * Flag to determine whether user tried to book appointment before
     * logging in or signing up.
     */
    @tracked hasPendingAppointments = false;

    /**
     * Flag to determine whether to load scheduling page before saving
     * pending appointment.
     */
    @tracked enableTemporaryLoading = false;

    /**
     * Appointment object to be booked upon logging in or signing up.
     */
    @tracked appointment?: Appointment;

    /**
     * Location that appointment is booked at.
     */
    @tracked property?: LocationModel;

    loadAppointment(appointment: Appointment, property: LocationModel) {
        (this.hasPendingAppointments = true),
            (this.appointment = appointment),
            (this.property = property);
    }

    resetAppointmentData() {
        this.hasPendingAppointments = false;
        this.enableTemporaryLoading = false;
        this.appointment = undefined;
        this.property = undefined;
    }

    transitionRoute() {
        if (this.property) {
            this.enableTemporaryLoading = true;
            const propertyId = this.property.id;
            const baseRoute = 'context.location';
            this.router.transitionTo(baseRoute, propertyId);
        }
    }

    async processPendingAppointments() {
        this.transitionRoute();
        if (this.appointment && this.property) {
            try {
                // Save the appointment.
                await this.appointment.save();
                // DO NOT COPY THIS, PLEASE IMPLEMENT THE SUGGESTION AND TEST.
                // @ts-expect-error `location` is async so this code should be replaced with: `await (await this.appointment.location).reload();`.
                await this.appointment.location.reload();

                this.property.nextAppointment = this
                    .appointment as unknown as AsyncBelongsTo<Appointment>;

                const tourWindowStart = new Date(
                    subMinutes(this.appointment.dateTimeUtc, 5)
                );
                const withinTourWindow =
                    tourWindowStart <= this.appointment.createdAt;

                // Send the CPID verification if user needs to be verified and it is not within tour time.
                if (this.property.reverificationRequired && !withinTourWindow) {
                    await this.checkIn.sendCheckpointIdVerification(
                        this.appointment.id,
                        false
                    );
                }
            } catch (response) {
                return this.ajax.handleAjaxError(
                    response,
                    'Unable to request Self-Guided Tours appointment. Please try again later.'
                );
            } finally {
                // Reset stored appointment data.
                this.resetAppointmentData();
            }
        }
    }
}
