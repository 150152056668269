import Model, { attr } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing mapping information between dealer external identifier and internal ID.
 */
export default class ExternalIdentifier extends Model {
    /**
     * Internal ID mapped to external identifier.
     *
     * @type {number}
     */
    @attr('number', { defaultValue: 0 })
    internalId;
}
