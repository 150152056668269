/**
 * Create an object url from a data url.
 *
 * @param {string} dataUrl
 * @returns {string} The object url
 */
export function dataUrlToObjectUrl(dataUrl) {
    if (!dataUrl) {
        return null;
    }

    const data = dataUrl.split(',');
    const byteString = window.atob(data[1]);
    const fileType = data[0].split(':')[1].split(';')[0];

    const bytes = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        bytes[i] = byteString.charCodeAt(i);
    }

    const file = new Blob([bytes.buffer], { type: fileType });
    return URL.createObjectURL(file);
}

/**
 * Gets the community site map object url and caches it in the context.
 *
 * @param {ContextModel} context
 * @param {Number} groupId
 * @param {Ajax} ajax
 */
export async function getCommunitySiteMapObjectUrl(context, groupId, ajax) {
    // Initialize cache if undefined.
    if (!context.communitySiteMapObjectUrls) {
        context.communitySiteMapObjectUrls = {};
    }

    // Fetch the map if it is not cached.
    // There can be multiple upcoming appointments from different groups with different maps.
    if (!(groupId in context.communitySiteMapObjectUrls)) {
        const dataUrl = await ajax.apiRequest(`/fileUploads/map/${groupId}`);

        context.communitySiteMapObjectUrls[groupId] = dataUrlToObjectUrl(
            dataUrl.value
        );
    }
}
