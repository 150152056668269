import Model, { attr } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing user profile password.
 */
export default class ProfilePassword extends Model {
    /**
     * User's new password.
     *
     * @type {String}
     */
    @attr('string')
    password;

    /**
     * User's password confirmation.
     *
     * @type {String}
     */
    @attr('string')
    confirmPassword;

    /**
     * Authorization token used when resetting password.
     *
     * @type {String}
     */
    @attr('string')
    token;
}
