import ProtectedRoute from 'unattended-showing/classes/protected-route';
import { inject as service } from '@ember/service';

/**
 * @classdesc
 * Route for viewing and managing existing Self-Guided Tours appointments.
 */
export default class AppointmentRoute extends ProtectedRoute {
    @service store;

    /**
     * @returns {{appointments: (model.appointment|Promise)}}
     */
    model() {
        const { context } = this.modelFor('context'),
            appointments = this.store.findAll('appointment');

        return {
            appointments,
            context
        };
    }
}
