import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import {
    createRowItem,
    createNativeSubField
} from '../../components/input-form-row/component';
import { inject as service } from '@ember/service';
import {
    validateTextInputCustomRegex,
    validateTextInputEmail
} from '../../utils/validators';

/**
 * @classdesc
 * Controller for user profile.
 */
export default class ProfileController extends Controller {
    @service notificationManager;
    @service ajax;
    @service modals;
    @service router;

    /**
     * Save profile model.
     *
     * @type {models.Profile|promise}
     */
    @action async save(profile) {
        try {
            const result = await profile.save();
            this.notificationManager.addSuccess(
                'Your profile has been updated.'
            );
            return result;
        } catch (response) {
            this.ajax.handleAjaxError(
                response,
                'Unable to update profile. Please try again later.'
            );
        }
    }

    /**
     * Deletes a user's account.
     *
     * @param {*} profile
     */
    @action delete(profile) {
        this.modals.showConfirmationDialog(
            'Confirm Delete',
            'Are you sure you want to delete your account?',
            {
                text: 'Confirm',
                css: 'delete-profile',
                action: async () => {
                    try {
                        await profile.destroyRecord();
                        this.router.transitionTo('context.logout');
                    } catch (response) {
                        this.ajax.handleAjaxError(
                            response,
                            'Unable to delete profile. Please try again later.'
                        );
                    }
                }
            }
        );
    }

    /**
     * Returns object for country data
     *
     * @type {Array<{zipValidator: {Function}}>}
     */
    @computed('model.countries')
    get zipValidators() {
        return this.model.countries.map((country) =>
            validateTextInputCustomRegex.bind(
                null,
                new RegExp(country.zipValidator)
            )
        );
    }

    /**
     * Returns data needed to construct user input form on template.
     *
     * @type {Array<{label: {String}, subFields: Array<{inputType: {String}, propertyName: {String}}>, validator: {Function}, errorMessage: {String}}>}
     */
    get userInfoFields() {
        const fnTextValidate = validateTextInputCustomRegex.bind(
            null,
            /[a-zA-Z]+/
        );
        const fnNumberValidate = validateTextInputCustomRegex.bind(
            null,
            /^[0-9]+$/
        );
        const postalCodeValidate = (input) =>
            this.zipValidators.some((validator) => validator(input));

        return [
            createRowItem(
                'First name',
                [createNativeSubField('firstName')],
                fnTextValidate,
                'Please enter your first name.'
            ),
            createRowItem(
                'Last name',
                [createNativeSubField('lastName')],
                fnTextValidate,
                'Please enter your last name.'
            ),
            createRowItem(
                'Email',
                [createNativeSubField('email')],
                validateTextInputEmail,
                'Please enter a valid email.'
            ),
            createRowItem(
                'Mobile number',
                [createNativeSubField('mobileNumber', 'text', 11)],
                fnNumberValidate,
                'Please enter a valid mobile number (include area code).'
            ),
            createRowItem(
                'Postal code',
                [createNativeSubField('zipCode', 'text', 5)],
                postalCodeValidate,
                'Please enter a valid postal code.'
            )
        ];
    }
}
