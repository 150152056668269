import ApplicationSerializer from './application';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

/**
 * @classdesc
 * Serializer for SignUp model so profile and password data are sent along to backend.
 */
export default class SignUpSerializer extends ApplicationSerializer.extend(
    EmbeddedRecordsMixin
) {
    isEmbeddedRecordsMixinCompatible = true;

    /** override **/
    attrs = {
        profile: {
            embedded: 'always'
        },
        password: {
            embedded: 'always'
        }
    };
}
