import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
    @service router;
    @service metrics;

    constructor(...args) {
        super(...args);

        const { router } = this;

        this.metrics.trackPage({
            page: router.currentURL,
            title: router.currentRouteName || 'unknown'
        });
    }
}
