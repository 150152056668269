import Route from '@ember/routing/route';

/**
 * @classdesc
 * Base class for routes that automatically scrolls to the top of the page.
 */
export default class BaseRoute extends Route {
    activate() {
        super.activate();
        window.scrollTo(0, 0);
    }
}
