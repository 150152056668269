import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';
import Context from '../../models/context';
import Session from 'unattended-showing/services/session';

interface ContextModel {
    context: Promise<Context>;
}

interface ProfileSessionService extends Session {
    data: {
        authenticated: {
            profileId: string;
        };
    };
}
export default class ProfileSgt3Route extends Route {
    @service declare router: ServiceRegistry['router'];
    @service declare store: ServiceRegistry['store'];
    @service declare session: ProfileSessionService;

    /**
     * Returns profile model.
     *
     * @type {{profile: (models.Profile|promise)}}
     */
    async model() {
        try {
            const contextModel = this.modelFor('context') as ContextModel;
            const context = await contextModel.context;
            const countries = await this.store.findAll('country');
            const profile = await this.store.findRecord(
                'profile',
                this.session.data.authenticated.profileId
            );

            return {
                profile,
                countries,
                context
            };
        } catch (error) {
            this.send('error', error);

            return {};
        }
    }

    async afterModel(model: { context: Context }) {
        if (!model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
