import { computed, setProperties } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import startOfDay from 'date-fns/startOfDay';
import { getCommunitySiteMapObjectUrl } from 'unattended-showing/helpers/file-utils';

/**
 * Main controller for appointment route.
 */
export default class AppointmentController extends Controller {
    @service session;
    @service store;
    @service router;
    @service ajax;
    @service checkIn;

    /**
     * Each Appointment is placed in a map, categorized by individual days. This gets the map key for the Appointment object.
     *
     * @param {models.Appointment} appointment
     * @type {number}
     */
    appointmentKey(appointment) {
        return startOfDay(new Date(appointment.dateTimeLocal)).valueOf();
    }

    /**
     * Get appointments filtered by filter function, sorted ascending, and placed into day-buckets.
     *
     * @param {function} filter
     * @param {Boolean} reverseDayOrder
     *
     * @type {Array<{day: {Date}, appointments: Array<models.Appointment>>}
     */
    async filteredAppointments(filter, reverseDayOrder = false) {
        const appointments = await this.model.appointments,
            context = await this.model.context;
        await Promise.all(
            appointments.map((a) => {
                const locationId = a.belongsTo('location').id();
                return this.store.findRecord('location', locationId, {
                    adapterOptions: {
                        queryParams: {
                            systemGroupId: context.systemGroupId,
                            loadAppointments: false
                        }
                    }
                });
            })
        );

        const appointmentMap = new Map();

        // Start with all of the user's appointments.
        // eslint-disable-next-line ember/no-array-prototype-extensions
        appointments
            .toArray()
            .filter(filter)
            .sort((a, b) => new Date(a.dateTimeUtc) - new Date(b.dateTimeUtc))
            .forEach((appointment) => {
                const key = this.appointmentKey(appointment);

                if (appointmentMap.has(key)) {
                    appointmentMap.get(key).push(appointment);
                } else {
                    appointmentMap.set(key, [appointment]);
                }
            });

        const appointmentObjects = [...appointmentMap].map(
            ([day, appointments]) => ({
                day: new Date(day),
                appointments
            })
        );

        if (reverseDayOrder) {
            return appointmentObjects.reverse();
        }

        return appointmentObjects;
    }

    /**
     * Get all of the user's upcoming appointments (including pending appointments).
     *
     * @type {Array<{day: {Date}, appointments: Array<models.Appointment>}>}
     */
    @computed('model.appointments.[]')
    get futureAppointments() {
        return this.filteredAppointments((appointment) => {
            // Return future appointments with non-empty date and location.
            return (
                !!appointment.current &&
                !!appointment.dateTimeUtc &&
                !!appointment.fullAddress
            );
        }, false);
    }

    /**
     * Get all of the user's past appointments (not including pending appointments).
     *
     * @type {Array<{day: {Date}, appointments: Array<models.Appointment>}>}
     */
    @computed('model.appointments.[]')
    get pastAppointments() {
        return this.filteredAppointments((appointment) => {
            // Return past appointments with non-empty date and location.
            return (
                !!appointment.past &&
                !!appointment.dateTimeUtc &&
                !!appointment.fullAddress
            );
        }, true);
    }

    /**
     * Delete appointment.
     *
     * @param {models.Appointment} appointment
     * @returns {models.Appointment|Promise}
     */
    @action async deleteAppointment(appointment) {
        try {
            await appointment.destroyRecord();
            location.reload();
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                "We couldn't cancel your appointment."
            );
        }
    }

    /**
     * Check-in the user.
     *
     * @param {models.Appointment} appointment
     */
    @action async checkInAppointment(appointment) {
        const location = await appointment.location;
        const cpidEnabled = location.checkpointIdEnabled;
        await this.checkIn.appointmentCheckIn(location, appointment);

        if (cpidEnabled) {
            setProperties(appointment, {
                showCheckIn: false
            });

            this.goToLocation(appointment);
        } else {
            window.location.reload();
        }
    }

    /**
     * Send user the CPID verification.
     *
     * @param {models.Appointment} appointment
     */
    @action async reVerify(appointment) {
        await this.checkIn.sendCheckpointIdVerification(appointment.id);
        this.goToLocation(appointment);
    }

    /**
     * Transition to location page for appointment.
     *
     * @param {models.Appointment} appointment
     */
    @action goToLocation(appointment) {
        const locationId = appointment.belongsTo('location').id();

        if (locationId) {
            const contextId = appointment.subdomain;

            // If the subdomain is not populated, do not try to transition.
            if (!contextId) {
                return;
            }
            this.router.transitionTo('context.location', contextId, locationId);
            return;
        }
    }

    /**
     * Opens a new tab for the community site map.
     *
     * @function
     */
    @action async openCommunitySiteMap(groupId, ev) {
        ev.preventDefault();

        await getCommunitySiteMapObjectUrl(
            this.model.context,
            groupId,
            this.ajax
        );

        window.open(
            this.model.context.communitySiteMapObjectUrls[groupId],
            '_blank',
            'noopener noreferrer'
        );
    }
}
