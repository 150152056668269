import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';

/**
 * @classdesc Personal Information sub-route for user sign-up.
 */
export default class SignUpPersonalInformationRoute extends Route {
    @service session;
    @service store;

    /**
     * Verify that user is not logged in; otherwise redirect user to appointments page. We do not use the UnauthenticatedRouteMixin
     * mixin here because we login the user after signing up. That mixin will cause the user to be redirected immediately.
     */
    beforeModel() {
        this.session.prohibitAuthentication('context.appointment');
    }

    /**
     * Returns model for new user sign-up.
     *
     * @returns {{signUp: (models.SignUp|promise)}}
     */
    async model() {
        const signUp = this.store.createRecord('sign-up');
        const countries = await this.store.findAll('country');
        signUp.set('profile', this.store.createRecord('profile'));
        signUp.set('password', this.store.createRecord('profile-password'));

        return { signUp, countries };
    }

    /**
     * Deletes created model if not saving when exiting route.
     */
    async deactivate() {
        const profile = await this.currentModel.signUp.profile;
        const password = await this.currentModel.signUp.password;

        // Need the explicit run loops here for unit tests.
        // Do not copy this deprecated usage. If you see this, please convert to using ember-lifeline.
        // eslint-disable-next-line ember/no-runloop
        run(() => profile.unloadRecord());
        // Do not copy this deprecated usage. If you see this, please convert to using ember-lifeline.
        // eslint-disable-next-line ember/no-runloop
        run(() => password.unloadRecord());
        // Do not copy this deprecated usage. If you see this, please convert to using ember-lifeline.
        // eslint-disable-next-line ember/no-runloop
        run(() => this.currentModel.signUp.unloadRecord());
    }
}
