import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
/**
 * Route for user password change.
 */
export default class ChangePasswordRoute extends Route {
    @service store;

    /**
     * Creates new ProfilePassword record.
     *
     * @returns {{profilePassword: (models.ProfilePassword|promise)}}
     */
    model() {
        return {
            profilePassword: this.store.createRecord('profile-password')
        };
    }

    /**
     * Deletes created model if not saving when exiting route.
     */
    deactivate() {
        this.currentModel.profilePassword.unloadRecord();
    }
}
