import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type { Registry as ServiceRegistry } from '@ember/service';
import Context from '../../models/context';

export default class FaqSgt3Route extends Route {
    @service declare router: ServiceRegistry['router'];

    async afterModel(model: { context: Context }) {
        if (!model.context.useSGT3) {
            this.router.transitionTo('context.not-found', 'not-found');
        }
    }
}
