import Model, { attr, belongsTo } from '@ember-data/model';

import type { AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from './location';
/**
 * @classdesc
 * Model for storing locations' unavailable appointment slots.
 */
export default class SanitizedAppointment extends Model {
    /**
     * Date of unavailable appointment slot.
     */
    @attr('date')
    declare dateTimeUtc: Date;

    /**
     * Whether the appointment is a Tour Now.
     */
    @attr('boolean')
    declare isTourNow: boolean;

    /**
     * Location reference of unavailable appointment slot.
     */
    @belongsTo('location', { inverse: 'unavailablePeriods', async: true })
    declare location: AsyncBelongsTo<LocationModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'sanitized-appointment': SanitizedAppointment;
    }
}
