import Controller from '@ember/controller';
import ENV from 'unattended-showing/config/environment';
import { htmlSafe } from '@ember/template';

export default class LandingPageController extends Controller {
    /**
     * The root URL path for all SVG sprites.
     *
     * @type {String}
     */
    get svgSpriteRoot() {
        return htmlSafe(`${ENV.rootURL}assets/svg-sprites`);
    }

    /**
     * Returns check list items for title area section of landing page.
     *
     * @type {Array<{String}>}
     */
    get titleAreaCheckList() {
        return [
            'Around 30% of prospects who stop by a leasing office find the leasing office closed',
            "Over 40% of U.S. adults have searched for an apartment in the last two years, yet four in ten have passed on an apartment because they couldn't find time to see it"
        ];
    }

    /**
     * Returns "How it Works" steps for landing page.
     *
     * @type {{step: string, text: string}[]}
     */
    get howItWorksSteps() {
        return [
            'Enable Self-Guided Tours links on your property page',
            'Prospective resident finds a property online and schedules Self-Guided Tours via the Self-Guided Tours link',
            'The renter receives a confirmation text with a temporary lock code to access the property',
            'A notification is sent to the property manager or builder letting them know it is being viewed',
            'When the tour is complete, the temporary digital smart key is deleted and another notification is sent to the property manager or builder, allowing them to follow up with the prospective resident'
        ].map((text, idx) => ({
            step: idx + 1,
            text
        }));
    }

    get solutionExplanation() {
        return [
            {
                id: 1,
                header: 'Self-Guided Tours',
                description:
                    'Prospective homeowners or renters can schedule a tour whenever convenient with a user code that keeps all access secure and accounted for.',
                icon: 'touchpad-lock',
                homeOwner: true,
                propertyManager: true
            },
            {
                id: 2,
                header: 'Operational Efficiencies and Asset Protection',
                description:
                    "Self-Guided Tours, keyless locks that don't need to be rekeyed, leak detectors, HVAC efficiency and predictive notifications, curb to couch access control," +
                    ' and other services help your staff save time and money, while also helping operators better protect assets.',
                icon: 'thermostat',
                propertyManager: true
            },
            {
                id: 3,
                header: 'Resident Amenity',
                description:
                    'Keyless access, HVAC savings and add-ons like video cameras or monitored life safety services provide in-demand amenities that short and long term renters are looking for.',
                icon: 'lock',
                propertyManager: true
            },
            {
                id: 4,
                header: 'Upsell Options',
                description:
                    'Provide an easy upgrade path to professionally monitored security, life safety, and video monitoring.',
                icon: 'video',
                homeOwner: true,
                propertyManager: true
            },
            {
                id: 5,
                header: 'Single App Experience',
                description:
                    "Seamlessly integrate with homeowners' devices. Control the system through one application, via computer, tablet or smartphone.",
                icon: 'phone',
                homeOwner: true,
                propertyManager: true
            }
        ];
    }

    /**
     * Returns FAQ items for landing page. Each question must have a unique id in order for dropdown tabs to work.
     *
     * @type {Array<{section: {String}, questions: Array<{id: {Number}, question: {String}, answers: Array<{String}>}>}>}
     */
    get faq() {
        return [
            {
                section: 'Overview',
                questions: [
                    {
                        id: 1,
                        question:
                            'How does Alarm.com’s Self-Guided Tours feature work?',
                        answers: [
                            'After creating an account, you’ll have access to search all properties that have been loaded into the Self-Guided Tours feature by the property manager.',
                            'After finding your desired property, you can view a calendar of available timeslots in which you can schedule a viewing.',
                            'After selecting a time on the calendar, you will receive a lock code for the front door lock which will be usable for the duration of your scheduled showing.',
                            'When your visit is complete, you’ll simply want to close and lock the door as you leave. That’s it!'
                        ]
                    },
                    {
                        id: 2,
                        question: 'Do Prospects need to download an app?',
                        answers: [
                            'No app is needed. Simply input the code that is emailed to you in the lock at the property.'
                        ]
                    },
                    {
                        id: 3,
                        question: 'Is Wi-Fi needed?',
                        answers: [
                            'No, you can implement Self-Guided Tours with a cellular lock and a hub.'
                        ]
                    },
                    {
                        id: 4,
                        question:
                            'Is the property owned or managed by Alarm.com?',
                        answers: [
                            'No, the property is owned and managed by the property manager independently from Alarm.com. Alarm.com is the technology provider that the property manager leverages to provide a self-guided tour to their tenant prospects.'
                        ]
                    }
                ]
            },
            {
                section: 'Creating an Account',
                questions: [
                    {
                        id: 5,
                        question: 'Why won’t it let me create an account?',
                        answers: [
                            'Make sure all requested information is filled out from the Identity verification page. All boxes are required to be completed for sign up.',
                            'Double check that your credit card number is entered correctly.',
                            'If you do not have a credit card, please contact the property manager to see if there are alternative methods for viewing the home.'
                        ]
                    },
                    {
                        id: 6,
                        question:
                            'Does Alarm.com keep my Credit/Debit Card info on file?',
                        answers: [
                            'No, we will not store your credit card information after the verification check is complete.'
                        ]
                    },
                    {
                        id: 7,
                        question: "What if I don't have a credit card?",
                        answers: [
                            'A debit or credit card will be required to create a Self-Guided Tours account for verification purposes. If you do not have a credit card, please contact the property manager for alternative methods of access to view the property.'
                        ]
                    },
                    {
                        id: 8,
                        question: 'Why do I need a credit card?',
                        answers: [
                            'A credit card is required to schedule Self-Guided Tours to verify your stated identity.'
                        ]
                    }
                ]
            },
            {
                section: 'Viewing a Property',
                questions: [
                    {
                        id: 9,
                        question:
                            'Why can’t I find the property I’m looking for?',
                        answers: [
                            'It’s possible that the property is not integrated with Alarm.com’s website, or may no longer be available. If the property you are looking for cannot be found, please contact the property manager to confirm its availability.'
                        ]
                    },
                    {
                        id: 10,
                        question:
                            'Why won’t it let me schedule a showing on the property?',
                        answers: [
                            'The ability to schedule a showing is dependent on wireless communication both in and outside of the home. In the event the lock is unable to communicate through the Self-Guided Tours app, please contact the property manager for alternative methods to schedule and view the property.',
                            'Another reason may be that the property is no longer available but not removed yet. Please contact the property manager for alternative methods to schedule and view another property.'
                        ]
                    },
                    {
                        id: 11,
                        question: 'Why doesn’t my lock code unlock the lock?',
                        answers: [
                            'Double check that you are trying to use the assigned lock code during the previously scheduled period of time.',
                            'Double check that you are using the correct lock code.',
                            'To access: (1) if lock keypad has * or Schlage button, press it first. (2) Enter lock code. (3) Press * or Schlage button if present.',
                            'If the lock in unable to unlock, contact the property manager for assistance accessing the home.'
                        ]
                    },
                    {
                        id: 12,
                        question:
                            'Who do I contact if I notice something wrong at the property?',
                        answers: [
                            'If you identify property damage, or any other items you feel should be reported, please contact the property manager.'
                        ]
                    },
                    {
                        id: 13,
                        question: 'Can I see a property more than once?',
                        answers: [
                            'Yes, if the property is still available, then feel free to schedule additional showings on a property you’ve already visited.'
                        ]
                    }
                ]
            }
        ];
    }
}
