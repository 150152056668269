import Controller from '@ember/controller';
import { computed } from '@ember/object';

/**
 * Controller for FAQ page.
 */
export default class FaqController extends Controller {
    /**
     *
     * Function that returns content for FAQ page.
     *
     * @function
     *
     * @returns {Array<{title: String, questions: Array<{title: String, answers: Array<String>}>}>}
     */
    @computed('model.context.{hasPointCentralDealer,poweredByName}')
    get pageContents() {
        return (async () => {
            const poweredByName = await this.model.context.poweredByName;
            const forRentText = (await this.model.context.hasPointCentralDealer)
                ? ' for rent'
                : '';
            const noLongerAvailableText = (await this.model.context
                .hasPointCentralDealer)
                ? 'property has been recently reserved or leased'
                : 'property is no longer available';
            const unlockInstructions = (await this.model.context
                .hasPointCentralDealer)
                ? 'To unlock the door, enter your 6 digit lock code then press the (✔) or (*) key on the lower left corner of the keypad on the lock.'
                : 'To access: (1) if lock keypad has * or Schlage button, press it first. (2) Enter lock code. (3) Press * or Schlage button if present.';

            return [
                {
                    title: 'Overview',
                    questions: [
                        {
                            title: `How does ${poweredByName}’s Self-Guided Tours feature work?`,
                            answers: [
                                `After creating an account, you’ll have access to search all properties${forRentText} that have been loaded into the Self-Guided Tours feature by the property manager.`,
                                'After finding your desired property, you can view a calendar of available timeslots in which you can schedule a viewing.',
                                'After selecting a time on the calendar, you will receive a lock code for the front door lock which will be usable for the duration of your scheduled showing.',
                                'When your visit is complete, you’ll simply want to close and lock the door as you leave.  That’s it!'
                            ]
                        },
                        {
                            title: `Is the property owned or managed by ${poweredByName}?`,
                            answers: [
                                `No. The property is owned and managed by the property manager independently from ${poweredByName}. ${poweredByName} is the technology provider that the property manager leverages to provide a Self-Guided Tours experience to their tenant prospects.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Creating an Account',
                    questions: [
                        {
                            title: 'Why won’t it let me create an account?',
                            answers: [
                                'Make sure all requested information is filled out from the Identity verification page.  All boxes are required to be completed for sign up.',
                                'Double check that your credit card number is entered correctly.',
                                'If you do not have a credit card, please contact the property manager to see if there are alternative methods for viewing the home.'
                            ]
                        },
                        {
                            title: `Does ${poweredByName} keep my Credit/Debit Card info on file?`,
                            answers: [
                                'No.  We will not store your credit card information after the verification check is complete.'
                            ]
                        },
                        {
                            title: 'What if I don’t have a credit card?',
                            answers: [
                                'A debit or credit card will be required to create a Self-Guided Tours account for verification purposes.  If you do not have a credit card, please contact the property manager for alternative methods of access to view the property.'
                            ]
                        },
                        {
                            title: 'Why do I need a credit card?',
                            answers: [
                                'A credit card is required to schedule Self-Guided Tours to verify your stated identity.'
                            ]
                        }
                    ]
                },
                {
                    title: 'Viewing a Property',
                    questions: [
                        {
                            title: 'Why can’t I find the property I’m looking for?',
                            answers: [
                                `It’s possible that the property is not integrated with ${poweredByName}’s website, or may no longer be available${forRentText}.  If the property you are looking for cannot be found, please contact the property manager to confirm its availability.`
                            ]
                        },
                        {
                            title: 'Why won’t it let me schedule a showing on the property?',
                            answers: [
                                'The ability to schedule a showing is dependent on wireless communication both in and outside of the home.  In the event the lock is unable to communicate through the Self-Guided Tours app, please contact the property manager for alternative methods to schedule and view the property.',
                                `Another reason may be that the ${noLongerAvailableText} but not removed yet. Please contact the property manager for alternative methods to schedule and view another property.`
                            ]
                        },
                        {
                            title: 'Why doesn’t my lock code unlock the lock?',
                            answers: [
                                'Double check that you are trying to use the assigned lock code during the previously scheduled period of time.',
                                'Double check that you are using the correct lock code.',
                                unlockInstructions,
                                'If the lock is unable to unlock, contact the property manager for assistance accessing the home.'
                            ]
                        },
                        {
                            title: 'Who do I contact if I notice something wrong at the property?',
                            answers: [
                                'If you identify property damage, or any other items you feel should be reported, please contact the property manager.'
                            ]
                        },
                        {
                            title: 'Can I see a property more than once?',
                            answers: [
                                'Yes.  If the property is still available, you can feel free to schedule additional showings on a property you’ve already visited.'
                            ]
                        }
                    ]
                }
            ];
        })();
    }
}
