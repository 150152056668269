import SessionService from 'ember-simple-auth/services/session';
import ENV from 'unattended-showing/config/environment';

export default class Session extends SessionService {
    handleInvalidation(routeAfterInvalidation: string): void {
        const id = this.data?.contextId;
        super.handleInvalidation(
            id ? `${ENV.rootURL}${id}` : routeAfterInvalidation
        );
    }
}
