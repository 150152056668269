import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Route for redirecting to location's scheduling page using dealer's external identifier.
 */
export default class RedirectRoute extends Route {
    @service store;
    @service router;

    /** @override **/
    model(param) {
        return {
            externalIdentifier: this.store.findRecord(
                'external-identifier',
                param.external_identifier_id,
                {
                    adapterOptions: {
                        queryParams: {
                            systemGroupId:
                                this.modelFor('context').context.systemGroupId
                        }
                    }
                }
            )
        };
    }

    /** @override **/
    async afterModel(model) {
        const externalIdentifier = await model.externalIdentifier;
        this.router.transitionTo(
            'context.location',
            externalIdentifier.internalId
        );
    }
}
