import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dataUrlToObjectUrl } from 'unattended-showing/helpers/file-utils';

/**
 * @classdesc
 * The modal that prompts the user to accept the terms and conditions.
 */
export default class TermsAndConditionsModal extends Component {
    @service modals;
    @service ajax;

    @tracked isChecked = false;

    /**
     * Opens a new tab for the terms and conditions.
     *
     * @function
     */
    @action async openTermsAndConditions(ev) {
        ev.preventDefault();

        const context = this.args.model.context;
        if (!context.termsAndConditionsObjectUrl) {
            const dataUrl = await this.ajax.apiRequest(
                `/fileUploads/terms/${context.systemGroupId}`
            );

            context.termsAndConditionsObjectUrl = dataUrlToObjectUrl(
                dataUrl.value
            );
        }

        window.open(
            context.termsAndConditionsObjectUrl,
            '_blank',
            'noopener noreferrer'
        );
    }

    /**
     * Closes the modal.
     */
    @action closeAction() {
        this.modals.closeModal();
    }

    /**
     * Performs the save action and then closes the modal
     * @returns {Promise}
     */
    @action saveAndCloseAction() {
        return this.args.model.saveAction().then(() => this.closeAction());
    }
}
